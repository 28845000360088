import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { getLocalePrefix, t } from '../utils/lang-helper';
import './404.scss';

export default class NotFound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLang: 'en-gb'
    };
  }

  render() {
    const {
      state: { pageLang }
    } = this;

    return (
      <Layout pageLang={pageLang}>
        <div className="page-404">
          <div className="Wrapper">
            <h1>
              {t('We can’t seem to find the page you’re looking for.')}
            </h1>
            <p>
              {t('404 head text')}
            </p>
            <p>
              {t('Use our navigation above or')}
              &nbsp;
              <Link to={getLocalePrefix()}>{t('click here')}</Link>
              &nbsp;
              {t('to return to home')}
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}
