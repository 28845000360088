import { default as NotFoundOriginal } from './../404';

export default class NotFound extends NotFoundOriginal {
  constructor(props) {
    super(props);

    this.state = {
      pageLang: 'ja-jp'
    };
  }
}
